// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import DividendosEProventos from "layouts/dividendosEProventos";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import OperationsLayout from "layouts/operations";

// @mui icons
import Icon from "@mui/material/Icon";
import Posicao from "layouts/posicao";
import Logout from "layouts/authentication/logout";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Receita x Despesa",
    key: "receitaxdespesa",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/receitaxdespesa",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Nova operação",
    key: "operation",
    icon: <Icon fontSize="small">add_circle_outline_icon</Icon>,
    route: "/operation",
    component: <OperationsLayout />,
  },
  {
    type: "collapse",
    name: "Dividendos e Proventos",
    key: "dividendos",
    icon: <Icon fontSize="small">paid</Icon>,
    route: "/dividendos",
    component: <DividendosEProventos />,
  },
  {
    // type: "collapse",
    name: "renda variavel grafico",
    key: "renda_variavel_grafico",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/posicao",
    component: <Posicao />,
  },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    // type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    // type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    // type: "collapse",
    name: "Logout",
    key: "Logout",
    route: "/authentication/logout",
    component: <Logout />,
  },
  // {
  //   type: "collapse",
  //   name: "Logout",
  //   key: "logout",
  //   icon: <Icon fontSize="small">logout</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
  
];

export default routes;
