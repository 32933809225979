// import {ADA_SERVICE_TOKEN} from '../../Context/AuthProvider/utils';

const CustomFetch = (url, options = {}) => {
    const token = localStorage.getItem('tradez-token');
    const defaultHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
  
    const headers = {
      ...defaultHeaders,
      ...options.headers,
    };

        const resp = fetch(url, { ...options, headers });

        resp.then(e => e).then(f => {
            if(f.status === 401){
                window.location.href = "/authentication/sign-in";
                // localStorage.clear('tradez-token');
            }
        });

    return resp;
  };

  export default CustomFetch;