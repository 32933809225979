import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, PieChart, Pie, Legend, Cell } from 'recharts';

const CustomItem = ({ data }) => {

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="#344767"
            fontSize={14}
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {`${data[index].name} ${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

    return (
        <div style={{ width: '100%', height: 600 }}>
            <ResponsiveContainer>
                <PieChart>
                    <Pie 
                        dataKey="value" 
                        data={data} 
                        fill="#8884d8" 
                        label={renderCustomizedLabel} 
                        labelLine={false} 
                    >
                        {data?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default CustomItem;
