// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bgg.jpg";
import { useEffect, useState } from "react";
import { register } from "../api/authenticationApi";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

function Cover() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [isLoading, setIsLoagding] = useState(false);

  const navigate = useNavigate();

  const handleSignIn = async () => {
    setIsLoagding(true);
    await register(name, email, pass)
      .then(e => {

        if (e.status == 200) {
          return e.json();
        }
      })
      .then(e => {
        localStorage.setItem("tradez-token", `${e.accessToken}`);
        navigate('/dashboard');
        setIsLoagding(false);
      })
      .catch(e => {
        console.log(e);
        setIsLoagding(false);
      })
  }

  return (
    <CoverLayout image={bgImage}>
      <StyledCard className="StyledCard">
        <StyledContent
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDBox mt={-2} mb={-4}>
            <img width={250} src={`../p3602.png`} />
          </MDBox>
        </StyledContent>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput onChange={e => setName(e.target.value)} type="text" label="Nome" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput onChange={e => setEmail(e.target.value)} type="email" label="Email" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput onChange={e => setPass(e.target.value)} type="password" label="Password" variant="standard" fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Concordo com os&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Termos
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton disabled={isLoading} onClick={handleSignIn} variant="gradient" color="info" fullWidth>
                {isLoading
                  ? <CircularProgress color="inherit" size={16} />
                  :
                  'Registrar'}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Já tem cadastro?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Login
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </StyledCard>
    </CoverLayout>
  );
}

const StyledContent = styled(MDBox)`
background: radial-gradient(circle at 135% 631%, #f28371 79%, #f28371 1%, transparent 0%) ,#f0ba8d !important; 

display: flex;
justify-content: center;

@media screen and (max-width: 405px) {
  img {
    width: 220px
  }
}

@media screen and (max-height: 790px) {
  img {
    width: 190px
  }
}
`;

const StyledCard = styled(Card)`
@media screen and (max-height: 790px) {
  margin-top: -123px !important;
}

`;


export default Cover;
