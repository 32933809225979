import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bgg.jpg";
import login, { authenticateGoogleUser } from "../api/authenticationApi";

// login
import GoogleLogin from "react-google-login";
import { gapi } from 'gapi-script';
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [pass, setPass] = useState('');
  const [isLoading, setIsLoagding] = useState(false);

  const clientId = "429303876753-fijpsiil2qt0amasetqsj1320a38rmj6.apps.googleusercontent.com";

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId })
    })
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();

  const handleSubmitLogin = async () => {
    setIsLoagding(true);
    await login(userName, pass)
      .then(e => {

        if (e.status == 200) {
          return e.json();
        }
      })
      .then(e => {
        setIsLoagding(false);
        localStorage.setItem("tradez-token", `${e.accessToken}`);
        navigate('/dashboard');
      })
      .catch(e => console.log(e))
      .finally(() => setIsLoagding(false))
  }

  const responseGoogle = (response) => {
    const { profileObj: { email, name, imageUrl } } = response;

    authenticateUser(email, name, imageUrl);
  }

  const authenticateUser = async (userName, name, image) => {
    setIsLoagding(true);
    await authenticateGoogleUser(userName, name, image)
      .then(e => {

        if (e.status == 200) {
          return e.json();
        }
      })
      .then(e => {
        localStorage.setItem("tradez-token", `${e.accessToken}`);
        navigate('/dashboard');
      })
      .catch(e => console.log(e))
      .finally(() =>setIsLoagding(false))
  }

  return (
    <>
      <BasicLayout image={bgImage}>
        <Card>
          <StyledContent
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDBox mt={-2} mb={-6}>
              <img width={250} src={`../p3602.png`} />
            </MDBox>

            <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>

            </Grid>
          </StyledContent>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  onChange={e => setUserName(e.target.value)}
                  type="email"
                  label="Email"
                  fullWidth />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  onChange={e => setPass(e.target.value)}
                  type="password"
                  label="Password"
                  fullWidth />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton disabled={isLoading} onClick={handleSubmitLogin} variant="gradient" color="info" fullWidth>
                {isLoading 
                      ? <CircularProgress color="inherit" size={16} />
                      : 
                  'login'
                }
                </MDButton>
                <MDBox mt={2} mb={1} />

                <GoogleLogin
                  clientId={clientId}
                  onSuccess={responseGoogle}
                  render={renderProps => (
                    <MDButton disabled={isLoading} onClick={renderProps.onClick} variant="gradient" color="error" fullWidth>
                      {isLoading 
                      ? <CircularProgress color="inherit" size={16} />
                      : 
                      <GoogleIcon color="inherit" />
                      }
                    </MDButton>
                  )}
                // onFailure={responseGoogle}
                // icon={false}
                // cookiePolicy={'single_host_origin'} //testar isso aqui
                />

              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Ainda não se cadastrou?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Cadastre aqui
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    </>
  );
}

const StyledContent = styled(MDBox)`
  background: radial-gradient(circle at 135% 651%, #f28371 79%, #f28371 1%, transparent 0%) ,#f0ba8d !important; 
`;

export default Basic;
