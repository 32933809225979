// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import styled from "styled-components";
import { useState } from "react";
import KeepMountedModal from "components/Modal";
import AddNewRevenueExpenseForm from "../receitasxdespesas/addNewRevenueExpenseForm";

function Transaction({ color, icon, name, description, value, id }) {
  const [openModal, setOpenModal] = useState(false);


  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);    
  };

  return (

    <MDBox key={name} component="li" py={1} pr={2} mb={1}>
       <KeepMountedModal
        title="Editar Receita x Despesa"
        content={<AddNewRevenueExpenseForm title="Edidar Receita x Despesa" id={id} />}
        closeModal={handleCloseModal} // Passa o setter diretamente
        isOpen={openModal} // Passa o estado diretamente
      />
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" alignItems="center">
          <MDBox mr={2}>
            <MDButton variant="outlined" color={color} iconOnly circular>
              <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
            </MDButton>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDTypography variant="button" fontWeight="medium" gutterBottom>
              {name}
            </MDTypography>
            <MDTypography variant="caption" color="text" fontWeight="regular">
              {description}
            </MDTypography>
          </MDBox>
        </MDBox>
        <StyledContainer>
          <MDTypography variant="button" color={color} fontWeight="medium" textGradient>
            {value}
          </MDTypography>
          <MDBox pl={2} onClick={handleOpenModal}>
            <MDButton variant="text" color='secondary' iconOnly size="large">
              <Icon>edit</Icon>
            </MDButton>
          </MDBox>
        </StyledContainer>
      </MDBox>
    </MDBox>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;


// Typechecking props of the Transaction
Transaction.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Transaction;
