import CustomFetch from "components/API/customFetch";

const postRevenueExpense = async (requestBody) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestBody)
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/RevenueExpenses/create`, requestOptions);
      
        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
       return error;
    }
};

export const updateRevenueExpense = async (id, requestBody) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(requestBody)
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/RevenueExpenses/${id}`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};


export const deleteRevenueExpense = async (id) => {
    const requestOptions = {
        method: 'DELETE',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/RevenueExpenses/${id}`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};


export const getRevenueExpensesSummary = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/RevenueExpenses`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};

export const getRevenueExpensesList = async (startDate, endDate, pageSize, pageIndex) => {
    const url = new URL(`${process.env.REACT_APP_API}/RevenueExpenses/revenues-expenses-list`);

    const params = {
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
        pageSize: pageSize ?? null,
        pageIndex: pageIndex ?? null,
    };

    // Adiciona os parâmetros à URL
    Object.keys(params).forEach(key => {
        if (params[key] !== null) {
            url.searchParams.append(key, params[key]);
        }
    });

    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(url, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};

export const getRevenueExpensesYearDetails = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/RevenueExpenses/revenues-expenses-year`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }

};

export const getWeekExpensesDetails = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/RevenueExpenses/weekly-summary`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};


export const getRevenueExpenseById = async (id) => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/RevenueExpenses/${id}`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};


export default postRevenueExpense;