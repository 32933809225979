// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import ReceitaXDespesa from "layouts/billing/components/receitasxdespesas";
import ExtratoConsolidado from "layouts/billing/components/ExtratoConsolidado";
import Transactions from "layouts/billing/components/Transactions";
import { getRevenueExpensesSummary } from "layouts/billing/components/receitasxdespesas/revenueExpensesApi";
import { useEffect, useState } from "react";
import { Steps } from "intro.js-react";


function Billing(props) {
  const { remaningValue } = props;
  const [revenueXExpenses, setRevenueXExpenses] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [screenWidth, setScreenWidth] = useState(null);

  useEffect(() => {
    //TODO: aqui chama a API para buscar a posição atual do user;
    setScreenWidth(getWindowDimensions().width)

    getRevenueExpensesSummaryMethod();
    const onboarding = localStorage.getItem("patrimonio360onboarding-revenuexexpenses");
    setStepsEnabled(onboarding != 'true');
  }, []);

  const getRevenueExpensesSummaryMethod = async () => {
    await getRevenueExpensesSummary().then((data) => {
      setRevenueXExpenses(data);
      setIsLoading(false);
    })
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const stepsDesktop = [
    {
      element: '.menu-receitaxdespesa',
      intro: 'No Menu "Receita x Despesa", você pode inserir os seus ganhos e gastos',
    },
    {
      element: '.receitas',
      intro: 'Este é o valor somado de todas as receitas(ganhos) deste mês',
      // position: 'right',
      // tooltipClass: 'myTooltipClass',
    },
    {
      element: '.despesas',
      intro: 'Este é o valor somado de todas as despesas(gastos) deste mês',
    },
    {
      element: '.saldo',
      intro: 'Esta caixa mostra seu saldo disponível deste mês',
    },
    {
      element: '.addRevenueExpense',
      intro: 'Vamos começar! Adicione uma nova Receita ou Despesa',
    },
  ];

  const handleBeforeChange = (nextStepIndex) => {
    if (screenWidth < 1200 && nextStepIndex == 0) {
      setTimeout(() => {
        document.getElementsByClassName("menuHamburger")[0].click()

      }, 100);
    }

    if (screenWidth < 1200 && nextStepIndex == 1) {
      document.getElementsByClassName("menuHamburger")[0].click()
    }
  };

  return (
    <DashboardLayout>
      <Steps
        enabled={stepsEnabled}
        steps={stepsDesktop}
        initialStep={0}
        onExit={() => setStepsEnabled(false)}
        onBeforeChange={handleBeforeChange}
        onBeforeExit={false}
        exitOnOverlayClick={false}
        onComplete={() => localStorage.setItem("patrimonio360onboarding-revenuexexpenses", true)}
        options={{
          doneLabel: 'Vamos começar!',
          prevLabel: '<',
          nextLabel: '>'
        }}
      />
      <DashboardNavbar />
      <MDBox mt={3}>
        <MDBox mb={3} >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} xl={3}>
                  <DefaultInfoCard
                    classProp="receitas"
                    icon="trending_up"
                    title="Receitas"
                    description="Receitas deste mês"
                    value={
                      isLoading ? 'loading...'
                        :
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(revenueXExpenses?.revenue)}
                    color={'success'}
                  />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <DefaultInfoCard
                    classProp="despesas"
                    icon="trending_down"
                    title="Despesas"
                    description="Despesas deste mês"
                    value={
                      isLoading ? 'loading...'
                        :
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(revenueXExpenses?.expenses)}
                    color={'error'}
                  />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <DefaultInfoCard
                    classProp="saldo"
                    icon="account_balance_wallet"
                    title="Saldo"
                    description="Saldo"
                    value={
                      isLoading ? 'loading...'
                        :
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(revenueXExpenses?.revenue - revenueXExpenses?.expenses)}
                    color={'dark'}
                  />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <ReceitaXDespesa closeOnboarding={setStepsEnabled} remaningValue={revenueXExpenses?.remaningValue ?? '0'} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Transactions />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <ExtratoConsolidado />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
