import { Autocomplete, Card, FormControlLabel, Grid, Radio, RadioGroup, ThemeProvider, TextField, createTheme } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import styled from "styled-components";
import postOperation, { getAssets } from "./api/operationsApi";
import { useDebounce } from 'use-lodash-debounce'
import MDSnackbar from "components/MDSnackbar";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";
import { Steps } from "intro.js-react";
import { element } from "prop-types";

function OperationsLayout() {
    const [assets, setAssets] = useState([]);
    const [asset, setAsset] = useState('');
    const [search, setSearch] = useState('');
    const [quantity, setQuantity] = useState(null);
    const [price, setPrice] = useState();
    const [operationType, setOperationType] = useState('compra');
    const [date, setDate] = useState('');
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [screenWidth, setScreenWidth] = useState(null);
    const [openSnackMessage, setOpenSnackMessage] = useState(null);

    const theme = createTheme(
        {
            palette: {
                primary: { main: '#1976d2' },
            },
        },
        ptBR,
    );


    const debouncedValue = useDebounce(asset, 500);

    useEffect(() => {
        setScreenWidth(getWindowDimensions().width)

        const onboarding = localStorage.getItem("patrimonio360onboarding-novaoperacao");
        setStepsEnabled(onboarding != 'true');
    }, [])


    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }


    useEffect(() => {
        //TODO: aqui chama a API para buscar a posição atual do user;
        getAssetsList();

    }, [, debouncedValue, search]);

    const getAssetsList = async () => {
        await getAssets(search).then((data) => {
            setAssets(data.results?.map((item) => {
                return {
                    id: item.id,
                    label: item.ticker
                };
            }
            ));
        });
    };

    const handleDateChange = (date) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        setDate(formattedDate)
    };


    const handleSave = async () => {
        const body = {
            assetId: assets?.length < 1 ? null : asset?.id,
            customAssetName: search,
            quantity: quantity,
            averagePrice: price,
            operationType: operationType === 'compra' ? 1 : 2,
            operationDate: date
        };

        await postOperation(body)
        .then((data) => {
            if (data?.errorCode) {
                setOpenSnackMessage({ type: 'error', message: `${data?.message}` });
                return;
            }

            setAssets(data.results?.map((item) => {
                return {
                    id: item.id,
                    label: item.ticker
                };
            }
            ));

            setOpenSnackMessage({ type: 'success', message: 'Operação realizada com sucesso' });
        })
        .catch(e => {
            setOpenSnackMessage({ type: 'error', message: `${e}` });
        });
    };

    const stepsDesktop = [
        {
            element: '.menu-operation',
            intro: 'No Menu "Operações", você pode inserir os seus bens',
        },
        {
            element: '.ativoInput',
            intro: 'Neste campo, vc pode procurar por um ativo especifico, como uma ação por exemplo, ou inserir um bem de seu patrimônio',
            // position: 'right',
            // tooltipClass: 'myTooltipClass',
        },
        {
            element: '.ativoQuantidade',
            intro: 'Neste campo você deve inserir a quantidade referente ao bem inserido no campo anterior.',
        },
        {
            element: '.ativoPreco',
            intro: 'Insira o preço do bem, ação, FII, etc...'
        },
        {
            element: '.ativoData',
            intro: 'Esta caixa mostra seu saldo disponível deste mês',
        },
        {
            element: '.opetacaoType',
            intro: 'Escolha o tipo de operação.'
        },
        {
            element: '.salvarOperacao',
            intro: 'Agora é só salvar!'
        }
    ];

    const handleBeforeChange = (nextStepIndex) => {
        if (screenWidth < 1200 && nextStepIndex == 0) {
            setTimeout(() => {
                document.getElementsByClassName("menuHamburger")[0].click()

            }, 100);
        }

        if (screenWidth < 1200 && nextStepIndex == 1) {
            document.getElementsByClassName("menuHamburger")[0].click()
        }
    };

    return (
        <DashboardLayout>
            <Steps
                enabled={stepsEnabled}
                steps={stepsDesktop}
                initialStep={0}
                onExit={() => setStepsEnabled(false)}
                onBeforeChange={handleBeforeChange}
                onBeforeExit={false}
                exitOnOverlayClick={false}
                onComplete={() => localStorage.setItem("patrimonio360onboarding-novaoperacao", true)}
                options={{
                    doneLabel: 'Finalizar!',
                    prevLabel: '<',
                    nextLabel: '>'
                }}
            />
            <DashboardNavbar />
            <Grid item xs={12} sm={6} lg={3}>
                <MDSnackbar
                   color={openSnackMessage?.type}
                   icon="check"
                   title={openSnackMessage?.message}
                    // content="Hello, world! This is a notification message"
                    // dateTime="11 mins ago"
                    open={openSnackMessage}
                    onClose={() => setOpenSnackMessage(false)}
                    close={() => setOpenSnackMessage(false)}
                // bgWhite
                />
            </Grid>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <StyledMDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="dark">
                                    Nova operação
                                </MDTypography>
                            </StyledMDBox>
                            <MDBox pt={4} pb={3} px={3} >
                                <MDBox component="form" role="form">
                                    <StyledInput mb={2} id="input-ativo" className="ativoInput">
                                        <Autocomplete data-input
                                            // id="input-ativo"
                                            className="input-ativox"
                                            freeSolo
                                            options={assets}
                                            getOptionLabel={option => option.label}
                                            // value={'aapl'}
                                            onChange={(e, newValue) => setAsset(newValue)}
                                            renderInput={(params) => <TextField  {...params}
                                                onChange={(e) => setSearch(e.target.value)}
                                                label="Ativo" />}
                                        />
                                    </StyledInput>
                                    <MDBox mb={2} className="ativoQuantidade">
                                        <MDInput type="number"
                                            label="Quantidade"
                                            fullWidth
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)} />
                                    </MDBox>
                                    <MDBox mb={2} className="ativoPreco">
                                        <MDInput type="number" label="Preço" fullWidth
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)} />
                                    </MDBox>
                                    <ThemeProvider theme={theme}>
                                        <LocalizationProvider
                                            adapterLocale="pt-br"
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <StyledDatePicker className="ativoData"
                                                sx={{
                                                    display: 'flex', justifyContent: 'flex-end',
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: '#d2d6da',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#d2d6da',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#1A73E8',
                                                        },
                                                    }
                                                }}
                                                // format="DD/MM/YYYY" 
                                                label="Data da operação"
                                                onChange={handleDateChange}
                                                PopperProps={{
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [0, 10], // Deslocamento para ajustar a posição
                                                            }
                                                        }
                                                    ]
                                                }}

                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                    <MDBox mb={2} className="opetacaoType">
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="compra"
                                            name="radio-buttons-group"
                                            row
                                            onChange={(e) => setOperationType(e.target.value)}
                                        >
                                            <FormControlLabel value="compra" control={<Radio />} label="Compra" />
                                            <FormControlLabel value="venda" control={<Radio />} label="Venda" />
                                        </RadioGroup>
                                    </MDBox>
                                    <MDBox display="flex" alignItems="center" ml={-1}>

                                    </MDBox>
                                    <MDBox mt={4} mb={1} className="salvarOperacao">
                                        <MDButton variant="gradient" color="info" fullWidth onClick={handleSave}>
                                            Salvar
                                        </MDButton>
                                    </MDBox>

                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

const StyledMDBox = styled(MDBox)`
  background: radial-gradient(circle at 221% 201%, #f28371 0%, #f28371 65%, transparent 0%) ,#f0ba8d !important; 
`;

const StyledInput = styled(MDBox)`

[data-input] {
    & > div > div {
        // border: 1px solid red;
        height: 44px;
        
        & > input {
            padding: 0 5px !important;
        }
    }
}
`;

const StyledDatePicker = styled(DatePicker)`
    width: 100%;

    &>div{
        border-radius: 8px;
        height: 43px;
        padding: 0 3px;
    }

    input{
        padding: 9px 10px;
        font-size: 14px;
        color: rgb(73, 80, 87);
    }

    label{
        font-size: 14px;
        top: -4px;
    }

    button{
        right: 10px
    }
`;


export default OperationsLayout;