import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useMaterialUIController } from 'context';
import MDBox from 'components/MDBox';
import styled, { css } from 'styled-components';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function KeepMountedModal(props) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { content, closeModal, isOpen } = props;
  return (
      <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >

        <StyledBox sx={style}  $darkMode={darkMode}>
          {content}
        </StyledBox>
      </Modal>
  );
}

const StyledBox = styled(Box)`
  background: ${(props) => (props.$darkMode ? '#2c3c58' : 'white')} !important;
  border-radius: 15px;

  @media screen and (max-width: 767px) {
    padding: 0 !important;
    width: 96% !important;
  }
`;