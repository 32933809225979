import React, { createContext, useState, useMemo, useEffect } from "react";

// import {ADA_SERVICE_TOKEN, ADA_USERNAME} from './utils';

export const AuthContext = createContext();
function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const logout = () => {
        setUser(null);
        setToken(null);
        setIsLoggedIn(false)
        localStorage.clear();
        window.location.href = '/authentication/sign-in';
    };

    useEffect(() => {
        const localStorageToken = localStorage.getItem('tradez-token');
        if (isLoggedIn || localStorageToken != null) {
            setToken(localStorageToken);
            const localStorageUsername = localStorage.getItem('ADA_USERNAME');
            setUser('jaozin');
            setIsLoggedIn(true);
        }
    }, []);

    const authProviderMemoValue = useMemo(() =>
        ({ user, setUser, token, setToken, logout, setIsLoggedIn, isLoggedIn }),
        [user, setUser, token, setToken, logout, setIsLoggedIn, isLoggedIn]);

    return (
        <AuthContext.Provider value={authProviderMemoValue}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;