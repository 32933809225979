// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "layouts/billing/components/Transaction";
import { useEffect, useState } from "react";
import { getRevenueExpensesList } from "../receitasxdespesas/revenueExpensesApi";

function Transactions() {
  const [revenuesXExpensesList, setRevenuesXExpensesList] = useState([]);

  useEffect(() => {
    getRevenuesXExpensesListMethod();
  }, [])

  const getRevenuesXExpensesListMethod = async () => {
    await getRevenueExpensesList(null, null, 10, null).then((data) => {
      // setRevenueXExpenses(data);

      let groupedByMakeDate = data.results.reduce((acc, item) => {
        const date = item.makeDateString;

        // Se a data ainda não existe no acumulador, inicialize um array para ela
        if (!acc[date]) {
          acc[date] = [];
        }

        // Adicione o item ao array correspondente à data
        acc[date].push(item);

        return acc;
      }, {});

      const groupedArray = Object.entries(groupedByMakeDate).map(([makeDate, items]) => ({
        makeDate,
        items
      }));

      setRevenuesXExpensesList(groupedArray);
    })
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Extrato
        </MDTypography>
        <MDBox display="flex" alignItems="flex-start">
          <MDBox color="text" mr={0.5} lineHeight={0}>
            <Icon color="inherit" fontSize="small">
              date_range
            </Icon>
          </MDBox>
          <MDTypography variant="button" color="text" fontWeight="regular">
            01 - {revenuesXExpensesList[0]?.items[0]?.lastDayOfMonth}
            {revenuesXExpensesList[0]?.items[0]?.month} {new Date().getFullYear()}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        {revenuesXExpensesList?.map(e => {
          return (<>
            <MDBox mb={2}>
              <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
                {e.makeDate}
              </MDTypography>
            </MDBox>
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              {e.items?.map(e => {
                return (
                  <Transaction
                    color={e.type == 1 ? "success" : "error"}
                    icon={e.type == 1 ? "expand_less" : "expand_more"}
                    name={e.name}
                    description={e.makeDateString}
                    value={`R$ ${e.type == 1 ? e.value : '-' + e.value}`}
                    id={e.id}
                  />
                )
              })}

            </MDBox>

          </>)
        })}


      </MDBox>
    </Card>
  );
}

export default Transactions;
