import CustomFetch from "components/API/customFetch";

const postOperation = async (requestBody) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestBody)
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Operations`, requestOptions);
       
        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    };
};

export const getAssets = async (search) => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Assets?search=${search ?? ''}`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    };
};


export const getMonthlySummary = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Operations/monthly-summary`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};

export default postOperation;