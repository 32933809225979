import CustomFetch from "components/API/customFetch";

const getAssets = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Operations/position`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};

export const getProperty = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Operations/property`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};

export default getAssets;