import { useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dividendosEProventos/data/reportsBarChartData";
import reportsLineChartData from "layouts/dividendosEProventos/data/reportsLineChartData";

// RTL components
import DividendosTable from "layouts/dividendosEProventos/components/DividendosTable";
import OrdersOverview from "layouts/dividendosEProventos/components/OrdersOverview";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setDirection } from "context";
import CustomItem from "./dividendosChart";

function DividendosEProventos() {
  const [, dispatch] = useMaterialUIController();
  const { sales, tasks } = reportsLineChartData;

  // Changing the direction to rtl
  // useEffect(() => {
  //   setDirection(dispatch, "rtl");

  //   return () => setDirection(dispatch, "ltr");
  // }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* <CustomItem/> */}

      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <DividendosTable />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DividendosEProventos;
